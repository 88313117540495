:root {
    --black: #000000;
    --white: #ffffff;
    --backgroundColor: #e5e5e5;
    --buttonPrimaryColor: #fd9a26;
    --buttonPrimaryColor-hover: #fd9a26;
    --buttonAccentColor: #888888;
    --buttonAccentColor-hover: #888888be;
    --sidebar-button-active-color: #f4eae1;
    --sidebar-button-active-text-color: #f8786f;
    --table-row-border-bottom: #82828278;
}
