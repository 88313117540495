@import 'Component/Color/colors.scss';
.product-order {
    -webkit-print-color-adjust: exact;
    font-family: 'KohinoorBangla-Regular';
    .showroom-order-table {
        .ant-table {
            line-height: 5px;
            // font-family: Kohinoor Bangla;
        }
        //table header  row
        .ant-table-thead > tr {
            th {
                background-color: #e5e5e5;
                text-align: center;
                font-size: 19px;
                font-weight: bold;
                white-space: nowrap; // Prevent text from wrapping
                overflow: hidden; // Hide overflowed text
                text-overflow: ellipsis;
            }

            th:first-child {
                border-radius: 8px 0 0 8px !important;
            }
            th:last-child {
                border-radius: 0 8px 8px 0 !important;
            }
        }

        .ant-table-tbody > tr > td {
            text-align: center;
            font-weight: normal;
            color: #000000;
            font-size: 19px;
            border-bottom: 1px solid rgba(253, 154, 38, 0.5);
            white-space: nowrap; // Prevent text from wrapping
            overflow: hidden; // Hide overflowed text
            text-overflow: ellipsis;
        }
    }
    .ant-select-arrow {
        margin-top: -13px;
        color: #000000;
    }

    .ant-select-selector {
        border-radius: 5px !important;
        height: 41px !important;
        text-align: center;
        font-size: 14px;
    }

    .ant-picker {
        border-radius: 5px;
    }
}
