@import 'Component/Color/colors.scss';
.custom-order {
    .ant-table.ant-table-bordered > .ant-table-container {
        border: 0;
    }
    //table header  row
    .ant-table-thead > tr {
        th {
            background-color: rgba(255, 255, 255, 0.0001);
            text-align: center;
            font-size: 13px;
            font-weight: bold;
            border-top: 1px solid #f0f0f0;
        }

        th:nth-child(1) {
            border-left: 1px solid #f0f0f0;
        }

        th:nth-child(4) {
            border: 1px transparent;
            border-right: 1px transparent !important ;
            border-top: 1px transparent !important ;
        }
        th:last-child {
            border: 1px transparent;
            border-right: 1px transparent !important ;
        }
    }

    .ant-table-tbody > tr {
        td {
            text-align: center;
            font-weight: normal;
            color: #000000;
            font-size: 13px;
        }

        td:nth-child(1) {
            border-left: 1px solid #f0f0f0;
        }
        td:nth-child(4) {
            border-right: 1px transparent !important ;
            border: 1px transparent;
        }
        td:last-child {
            border-right: 1px transparent !important ;
            border: 1px transparent;
        }
    }
}
