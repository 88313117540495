$colorPrimary: '#FD9A26';
$colorTheme: '#F8786F';
$colorWhite: '#FFFFFF';
$colorBlack: '#000000';
$colorGreen: '#27AE60';
$colorGreenDark: '#0F9D58';
$colorGreenLight: '#E9F7EF';
$backgroundColorLight: linear-gradient(
    360deg,
    rgba(160, 181, 222, 0.5) 5%,
    rgba(31, 61, 119, 0.05) 100%
);
