button {
    color: var(--white);
    background-color: var(--buttonPrimaryColor);
    border: 0;
    cursor: pointer;
    align-items: center;
    align-content: center;
    justify-content: center;
    text-align: center;
}

button:hover {
    background-color: var(--buttonPrimaryColor-hover);
}

.button-container {
    display: flex;
    align-items: center;
    align-content: center;
    margin: 0px auto;
    justify-content: center;
    font-family: 'DMSans-Regular', 'FiraCode NF';
}

.button-container-left-icon {
    margin: auto;
    display: inline-flex;
}

.button-container-right-icon {
    margin: auto;
    display: inline-flex;
}

.ButtonXXL {
    height: 71px;
    width: 646px;
    border-radius: 10px;
    font-weight: 700;
    font-size: larger;
    line-height: 36px;
    font-style: normal;
}

.ButtonXL {
    height: 71px;
    width: 422px;
    border-radius: 10px;
    font-weight: 700;
    font-size: x-large;
    line-height: 36px;
    font-style: normal;
}

.ButtonL {
    height: 47px;
    width: 360px;
    border-radius: 10px;
    font-weight: 700;
    font-size: large;
    line-height: 36px;
    font-style: normal;
}

.ButtonM {
    height: 47px;
    width: 258px;
    border-radius: 10px;
    font-weight: 700;
    font-size: medium;
    line-height: 36px;
    font-style: normal;
}

.ButtonS {
    height: 47px;
    width: 189px;
    border-radius: 10px;
    font-weight: 700;
    font-size: medium;
    font-style: normal;
}

.ButtonXS {
    height: 47px;
    width: 130px;
    border-radius: 5px;
    font-weight: bolder;
    font-size: small;
    font-style: normal;
}

.ButtonXXS {
    height: 40px;
    width: 100px;
    border-radius: 10px;
    font-weight: normal;
    font-size: x-small;
    font-style: normal;
    box-shadow: 0px 4px 35px rgba(0, 0, 0, 0.25);
}

.ButtonXXXS {
    height: 30px;
    width: 82px;
    border-radius: 5px;
    font-weight: normal;
    font-size: 13px;
    font-style: normal;
    box-shadow: 0px 4px 35px rgba(0, 0, 0, 0.25);
}

.FlatButton {
    height: 61px;
    width: 300px;
    border-radius: 55px;
    font-weight: normal;
    font-size: 16px;
    font-style: normal;
    color: black;
    background-color: white;
}

.FlatButton:hover {
    height: 61px;
    width: 300px;
    border-radius: 55px;
    font-weight: normal;
    font-size: 16px;
    font-style: normal;
    color: var(--sidebar-button-active-text-color);
    background-color: var(--sidebar-button-active-color);
}
