.textAreaL {
    /* width: 1022px; */
    height: 176px !important;
    padding: 16px;
    border-radius: 15px;

    font-family: 'Kohinoor Bangla';
    font-style: normal;
    font-weight: normal;
    font-size: 19px;
    line-height: 28px;
    color: black;
}

textarea::placeholder {
    font-family: 'Kohinoor Bangla';
    font-style: normal;
    font-weight: normal;
    font-size: 19px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.5);
}
