.InputL {
    background: #ffffff;
    border: 1px solid rgba(128, 128, 128, 0.25);
    box-sizing: border-box;
    border-radius: 15px;
    /* width: 1022px; */
    height: 64px;
    font-size: 19px;
}

.InputM {
    background: #ffffff;
    border: 1px solid rgba(128, 128, 128, 0.25);
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    /* width: 475px; */
    height: 39px;
    font-size: 19px;
}

.InputS {
    background: #ffffff;
    border: 1px solid rgba(128, 128, 128, 0.25);
    box-sizing: border-box;
    border-radius: 15px;
    /* width: 350px; */
    height: 64px;
    font-size: 19px;
}

.InputXS {
    background: #ffffff;
    border: 1px solid rgba(128, 128, 128, 0.25);
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    /* width: 137px; */
    height: 27px;
    font-size: 19px;
}

.input .anticon {
    color: #00000042;
}
