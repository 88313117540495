@import 'Component/Color/colors.scss';
.add-product {
    font-family: 'KohinoorBangla-Regular';
    .basic-details {
        // padding: 19px 3px;
        border-radius: 10px;
        transition: 0.5s;
        background: var(--backgroundColor);
        // height: 492px;
        // width: 714px;
    }
    .images {
        // padding: 19px 3px;
        border-radius: 10px;
        transition: 0.5s;
        background: var(--backgroundColor);
        // height: 341px;
        // width: 714px;
        text-align: center;
    }

    .pricing {
        // padding: 19px 3px;
        border-radius: 10px;
        transition: 0.5s;
        background: var(--backgroundColor);
        // height: 341px;
        // width: 714px;
    }
    .org {
        // padding: 19px 3px;
        border-radius: 10px;
        transition: 0.5s;
        background: var(--backgroundColor);
        height: 346px;
        // width: 318px;
    }
    .ant-card-bordered {
        border: none;
    }

    .ant-card-head {
        border: 0;
        font-size: 19px;
        text-align: start;
        font-weight: bold;
    }

    // Action select dorpdown

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border-radius: 15px;
        height: 64px;
    }

    .ant-upload.ant-upload-drag {
        background: transparent;
        border: 0;
    }

    .input_price {
        background: #ffffff;
        border: 1px solid rgba(128, 128, 128, 0.25);
        box-sizing: border-box;
        border-radius: 15px;
        height: 64px;
        font-size: 19px;
    }

    .input_des {
        background: #ffffff;
        border: 1px solid rgba(128, 128, 128, 0.25);
        box-sizing: border-box;
        border-radius: 15px;
        height: 176px !important;
        font-size: 19px;
    }

    .ant-select-selection-item,
    .ant-select-selection-placeholder {
        line-height: 58px !important;
        font-size: 19px;
    }
}

#required:after {
    content: ' *';
    color: #ff4d4f;
}
