@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Bengali&display=swap');
body {
    margin: 0;
    font-family: 'KohinoorBangla-Regular', 'Noto Sans Bengali', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: 'KohinoorBangla-Regular', 'Noto Sans Bengali', sans-serif;
}
@font-face {
    font-family: 'KohinoorBangla-Regular';
    src: url('./assets/fonts/KohinoorBangla-Regular.otf') format('opentype');
}
