.H1{
    font-size: 36px;
    font-family: 'KohinoorBangla-Regular';
}

.H2{
    font-size: 24px;
    font-family: 'KohinoorBangla-Regular';
}
.H3{
    font-size: 19px;
    font-family: 'KohinoorBangla-Regular';
}
.H4{
    font-size: 16px;
    font-family: 'KohinoorBangla-Regular';
}
.H5{
    font-size: 13px;
    font-family: 'KohinoorBangla-Regular';
}
.textMenu{
    font-size: 24px;
    font-family: 'DMSans-Bold'
}