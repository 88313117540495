@import 'Component/Color/colors.scss';
.add-category {
    font-family: 'KohinoorBangla-Regular';
    .basic-details {
        // padding: 19px 3px;
        border-radius: 10px;
        transition: 0.5s;
        background: var(--backgroundColor);
        // height: 492px;
        // width: 714px;
    }

    .input_price {
        background: #ffffff;
        border: 1px solid rgba(128, 128, 128, 0.25);
        box-sizing: border-box;
        border-radius: 15px;
        height: 64px;
        font-size: 19px;
    }

    .input_des {
        background: #ffffff;
        border: 1px solid rgba(128, 128, 128, 0.25);
        box-sizing: border-box;
        border-radius: 15px;
        height: 176px !important;
        font-size: 19px;
    }
    //TABLE

    .product-list-table {
        margin-top: 20px;

        //sorting arrows

        .ant-table-column-sorter-up.active {
            color: var(--black);
        }

        .ant-table-column-sorter-down.active {
            color: var(--black);
        }

        //checkbox in table border colour
        .ant-checkbox-inner {
            border: 1px solid black;
            width: 14px;
            height: 14px;
            background-color: var(--backgroundColor);
        }

        //checkbox in table background color
        .ant-checkbox-checked::after {
            border: 1px solid transparent;
        }
        // on check checkbox the check icon style
        .ant-checkbox-checked .ant-checkbox-inner::after {
            border: 2px solid black;
            border-top: 0;
            border-left: 0;
        }

        //whole table background
        .ant-table {
            background-color: transparent;
        }
        //table header  row
        .ant-table-thead > tr > th {
            background-color: var(--backgroundColor);
            border-bottom: 1px solid var(--table-row-border-bottom);
            text-align: center;
            font-size: 19px;
        }
        // table body rows
        .ant-table-tbody > tr > td {
            background-color: var(--backgroundColor);
            border-bottom: 1px solid var(--table-row-border-bottom);
            text-align: center;
            font-size: 19px;
        }
        //seleted row color
        .ant-table-tbody > tr.ant-table-row-selected > td {
            background: #d2d2d2eb;
            border-bottom: 1px solid var(--table-row-border-bottom);
        }

        //on hover row
        .ant-table-tbody > tr.ant-table-row:hover > td {
            background: #d2d2d24f;
        }
    }
}
