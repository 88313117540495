.showroom-invoice {
    -webkit-print-color-adjust: exact;
    // width: 800px;
    margin: 0 auto;
    border: 1px #d3d3d3 solid;
    border-radius: 5px;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    padding: 20mm;
    min-height: 297mm;
    font-family: 'KohinoorBangla-Regular';
    span {
        font-weight: bold;
    }

    p {
        font-size: 19px;
    }
    .ant-table {
        line-height: 0;
    }

    .ant-table-container {
        border: 0 !important;
    }
    //table header  row
    .ant-table-thead > tr {
        th {
            background-color: #e5e5e5;
            text-align: start;
            font-size: 20px;
            font-weight: bold;
            border: 1px solid #828282;
        }

        th:first-child {
            border-radius: 3px 0px 0px 0px !important;
        }
        th:last-child {
            border-radius: 0px 3px 0px 0px !important;
            border-right: 1px solid #828282 !important;
        }
    }

    .ant-table-tbody > tr > {
        td {
            text-align: start;
            font-weight: normal;
            color: #000000;
            font-size: 20px;
            border: 1px solid #828282;
            border-top: 0;
        }
        td:last-child {
            border-right: 1px solid #828282 !important;
        }
    }

    .ant-table-summary {
        tr:first-child {
            height: 80px !important;
            border-left: 0 !important;
            td {
                border: 0 !important;
            }
            td:last-child {
                border-right: 0 !important;
            }
        }

        tr:last-child {
            td {
                border-right: 1px solid #000000 !important;
                border-top: 1px solid #000000 !important;
                border-bottom: 1px solid #000000 !important;
                text-align: center;
                color: #000000;
                font-weight: bold;
            }

            td:first-child {
                border-left: 1px solid #000000 !important;
            }
            td:last-child {
                border-right: 1px solid #000000 !important;
            }
        }
    }
}

@page {
    size: A4;
    margin: 0;
}

@media print {
    html,
    body {
        width: 310mm;
        height: 397mm;
    }
    .showroom-invoice {
        -webkit-print-color-adjust: exact;
        margin: 0;
        border: initial;
        border-radius: initial;
        width: initial;
        min-height: initial;
        box-shadow: initial;
        background: initial;
        page-break-after: always;

        .print-btn {
            display: none !important;
        }

        p {
            text-decoration: none !important;
        }
    }
}
