.ant-menu-item svg , .ant-menu-submenu svg, .ant-layout-sider-collapsed .ant-menu-item svg {
    margin-bottom: -4px;
}

.ant-menu {
    padding: 0 15px 0 15px;
    font-size: 15px;
    border-right: 1px solid black;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    color: var(--sidebar-button-active-text-color);
    background-color: var(--sidebar-button-active-color);
    border-radius: 55px;
}

.ant-menu-inline .ant-menu-item::after {
    border: 0;
    border-radius: 55px;
}

.ant-menu-submenu-selected {
    color: black;
}

.ant-menu-submenu-open {
    color: var(--sidebar-button-active-text-color);
}

/* to change the color of the arrow of the selected sub-menu list's menu */
.ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow, .ant-menu-item-icon {
    color: var(--sidebar-button-active-text-color);
}

.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
    background-color: var(--sidebar-button-active-color);
    color: var(--sidebar-button-active-text-color);
    border-radius: 55px;
}

/* for changing color of the collapse menu title hover */
.ant-menu-inline > .ant-menu-submenu:hover > .ant-menu-submenu-title:hover {
    color: var(--sidebar-button-active-text-color);
    background-color: var(--sidebar-button-active-color);
    border-radius: 15px;
}

/* for changing color and background color of the menu item without any submenu */
.ant-menu-item:hover {
    color: var(--sidebar-button-active-text-color);
    background-color: var(--sidebar-button-active-color);
    border-radius: 55px;
}

/* for changing the SVG colors */
.ant-menu-item-selected svg, .ant-menu-item-active svg {
    stroke: var(--sidebar-button-active-text-color);
    color: var(--sidebar-button-active-text-color);
    fill: var(--sidebar-button-active-text-color);
    stroke-width: 6%;
}

.ant-menu-submenu-active svg {
    stroke: var(--sidebar-button-active-text-color);
    color: var(--sidebar-button-active-text-color);
    fill: var(--sidebar-button-active-text-color);
    stroke-width: 6%;
}

.ant-menu-item-icon {
    margin-bottom: -4px;
}
