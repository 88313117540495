$boxBackgroundColor: #C4C4C4;

.headerText div{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3em;
}

.box{
    display: flex;
    flex-direction: row;
    background-color: $boxBackgroundColor;
    border-radius: 2em;
    border-color: black;

    .boxItems{
        display: flex;
        flex-direction: column;
        margin-left: 10em;
        margin-top: 1cm;

        .headline{
            margin-top: 3em;
        }
    }
    
}

