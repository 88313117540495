@import 'Component/Color/colors.scss';
.all-product {
    font-family: 'KohinoorBangla-Regular';
    //all text in tab
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: var(--black);
        font-family: 'KohinoorBangla-Regular';
        font-style: normal;
        font-weight: bold;
        font-size: 19px;
        line-height: 28px;
    }

    //tab highligted bar
    .ant-tabs-ink-bar {
        background: var(--sidebar-button-active-text-color);
    }

    //tab hr bar
    .ant-tabs-nav-wrap {
        border-bottom: 1px solid var(--table-row-border-bottom);
    }

    //sreach box
    .ant-input-affix-wrapper {
        border-radius: 5px;
    }

    .ant-input-prefix {
        color: #828282;
    }

    // Action select dorpdown

    .count {
        .ant-input-affix-wrapper {
            border-radius: 5px 0 0 5px !important;
            background: #e0e0e0;
            border: 1px solid #bdbdbd;
            border-right: 0;
            width: 204px;
        }

        .ant-input {
            background: #e0e0e0;
        }

        input::placeholder {
            color: #828282;
        }
    }

    .ant-select-selection-placeholder {
        color: black;
    }

    .ant-select-arrow {
        color: black;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border-radius: 0 5px 5px 0 !important;
        border: 1px solid #bdbdbd;
        border-left: 0;
    }

    //TABLE

    .product-list-table {
        margin-top: 20px;

        //sorting arrows

        .ant-table-column-sorter-up.active {
            color: var(--black);
        }

        .ant-table-column-sorter-down.active {
            color: var(--black);
        }

        //checkbox in table border colour
        .ant-checkbox-inner {
            border: 2.3px solid black;
            width: 15px;
            height: 15px;
            background-color: var(--backgroundColor);
        }

        //checkbox in table background color
        .ant-checkbox-checked::after {
            border: 1px solid transparent;
        }
        // on check checkbox the check icon style
        .ant-checkbox-checked .ant-checkbox-inner::after {
            border: 2px solid black;
            border-top: 0;
            border-left: 0;
        }

        //whole table background
        .ant-table {
            background-color: transparent;
        }
        //table header  row
        .ant-table-thead > tr > th {
            background-color: var(--backgroundColor);
            border-bottom: 1px solid var(--table-row-border-bottom);
            text-align: center;
            font-size: 19px;
        }
        // table body rows
        .ant-table-tbody > tr > td {
            border-bottom: 1px solid var(--table-row-border-bottom);
            font-size: 19px;
        }
        //available row
        .available {
            background-color: var(--backgroundColor);
            border-bottom: 1px solid var(--table-row-border-bottom);
            text-align: center;
        }

        //unavailable product row
        .unavailable {
            background-color: #fff2f0;
            border-bottom: 1px solid var(--table-row-border-bottom);
            text-align: center;
        }

        //seleted row color
        .ant-table-tbody > tr.ant-table-row-selected > td {
            background: #d2d2d2eb;
            border-bottom: 1px solid var(--table-row-border-bottom);
        }

        //on hover row
        .ant-table-tbody > tr.ant-table-row:hover > td {
            background: #d2d2d24f;
        }
    }
}
