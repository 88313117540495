@import 'Component/Color/colors.scss';
.login-page {
    display: flex;
    height: 100vh;
    background: $backgroundColorLight;
    .login-form {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .ant-form {
            flex: 1;
        }
        h4 {
            margin-bottom: 24px;
        }
        .login-form-button {
            width: 100%;
        }
        .ant-input {
            width: 100%;
        }
        .ant-form-item {
            margin-bottom: 14px;
            width: 100%;
        }
    }
    .login-logo {
        position: absolute;
        top: 24px;
        left: 24px;
    }

    .login-image {
        flex: 1;
        display: flex;
        background-size: cover;
        background-position: center;
        @media (max-width: 768px) {
            display: none;
        }
    }
}
