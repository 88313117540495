@import 'Component/Color/colors.scss';
.factory-order {
    .react-google-flight-datepicker {
        margin-top: -10px;
    }
    .showroom-order-table {
        .ant-table {
            line-height: 0;
            // font-family: Kohinoor Bangla;
        }
        .ant-table-container {
            border: 0;
        }
        //table header  row
        .ant-table-thead > tr {
            th {
                background-color: #e5e5e5;
                text-align: center;
                font-size: 19px;
                font-weight: bold;
                border-right: 1px solid #000000 !important;
            }

            th:first-child {
                border-radius: 8px 0 0 8px !important;
                border-left: 0;
            }
            th:last-child {
                border-radius: 0 8px 8px 0 !important;
                border-right: 0 !important;
            }
        }

        .ant-table-tbody > tr > td {
            text-align: center;
            font-weight: normal;
            color: #000000;
            font-size: 13px;
            border-bottom: 1px solid rgba(253, 154, 38, 0.5);
        }

        .ant-table-tbody > tr {
            td {
                border-right: 1px solid #000000 !important;
            }

            td:first-child {
                border-left: 0;
            }
            td:last-child {
                border-right: 0 !important;
            }
        }

        .ant-table-summary {
            tr:first-child {
                height: 80px !important;
                td {
                    border-right: 1px solid #000000 !important;
                }
                td:last-child {
                    border-right: 0 !important;
                }
            }

            tr:last-child {
                td {
                    border-right: 1px solid #000000 !important;
                    border-top: 1px solid #000000 !important;
                    border-bottom: 1px solid #000000 !important;
                    text-align: center;
                    color: #000000;
                    font-weight: bold;
                }

                td:first-child {
                    border-left: 0;
                }
                td:last-child {
                    border-right: 0 !important;
                }
            }
        }
    }
}
